import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { renderInput } from './renderInput';
import { renderSelect } from './renderSelect';
import { renderSubmitButton } from './renderSubmitButton';
import { updateInvitation } from '../../actions';

class PlusOneForm extends Component {
  onSubmit = formValues => {
    this.props.updateInvitation(this.props.code, formValues);
  };
  render() {
    return (
      <form
        className="form rsvp-form"
        onSubmit={this.props.handleSubmit(this.onSubmit)}
      >
        <Field
          name="personTwo"
          component={renderInput}
          label="Your Guest's name"
          type="text"
          placeholder="Your Guest's name"
        />
        <Field
          name="actualAttendees"
          component={renderSelect}
          label="Can you make it?"
          type="number"
          placeholder="Can you make it?"
          className="custom-select"
        >
          <option />
          <option value="2">Yes, my guest and I will be there!</option>
          <option value="1">Yes, just me - I'll be riding solo</option>
          <option value="0">Sorry, we're unable to attend.</option>
        </Field>
        <Field
          name="email"
          component={renderInput}
          label="Contact Email (to confirm your RSVP, and for important updates)"
          type="text"
          placeholder="Email"
        />
        <Field
          name="comments"
          component={renderInput}
          label="Any comments, questions, or dietary requirements?"
          type="text"
          placeholder="Comments"
        />
        {renderSubmitButton()}
      </form>
    );
  }
}

const validate = formValues => {
  const errors = {};

  if (!formValues.actualAttendees) {
    errors.actualAttendees = 'Please select an option';
  }

  if (formValues.actualAttendees > 1 && !formValues.personTwo) {
    errors.personTwo = 'Please provide a name for your guest';
  }

  if (formValues.actualAttendees < 2 && formValues.personTwo) {
    errors.personTwo =
      "You've provided a guest name, but only RSVPd for one guest?";
  }

  return errors;
};

const mapStateToProps = (state, ownProps) => {
  return {
    code: ownProps.code
  };
};

const formWrapped = reduxForm({
  form: 'invitationForm',
  validate
})(PlusOneForm);

export default connect(
  mapStateToProps,
  { updateInvitation }
)(formWrapped);
