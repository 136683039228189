import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { loadingBarReducer } from 'react-redux-loading-bar';

import invitationsReducer from './invitationsReducer';

export default combineReducers({
  form: formReducer,
  loadingBar: loadingBarReducer,
  invitations: invitationsReducer
});
