import React from 'react';

const ContentGoat = () => {
  return (
    <div className="container-fluid sml-hero">
      <div className="hero-content">
        <div className="row">
          <div className="col-md-3" />
          <div className="col-md-6">
            <h2 className="cursive">Reception @ Mountain Goat Brewery</h2>
            <p>
              Mountain Goat Beer is a brewery in the inner-Melbourne suburb of
              Richmond. Set in a converted red brick warehouse, it has a very
              welcoming vibe, and the ideal place for a fun wedding, with our
              friends and family.
            </p>
          </div>
          <div className="col-md-3" />
        </div>
      </div>
    </div>
  );
};

export default ContentGoat;
