import React from 'react';

export const renderSelect = ({ input, label, meta, children }) => {
  const className = `field ${
    meta.error && meta.touched ? 'custom-select is-invalid' : 'custom-select'
  }`;
  return (
    <div className="form-group mx-sm-3 mb-2">
      <label>{label}</label>
      <select {...input} className={className}>
        {children}
      </select>
      {_renderError(meta)}
    </div>
  );
};

const _renderError = ({ error, touched }) => {
  if (touched && error) {
    return <div className="invalid-feedback">{error}</div>;
  }
};
