import React from 'react';

const ContentTimeline = () => {
  return (
    <div className="container wedding-boxes">
      <div className="row">
        <div className="col-sm-1" />
        <div className="col-sm-10">
          <h2 className="cursive">We're getting married!</h2>
          <h3 className="serif">Saturday 7th March 2020</h3>
          <h3 className="serif">Melbourne, Victoria, Australia</h3>

          <div className="wedding-details">
            <div className="w-detail left">
              <i className="icon icon-heart" />
              <h4 className="title">WELCOME BBQ</h4>
              <h5 className="title">Friday 6th March 2020 - the day before</h5>
              <p>
                Weather permitting, join us for a relaxing BBQ on the banks of
                the Yarra river.
              </p>
              <p>
                Please provide an email address when you RSVP, and we'll provide
                details closer to the date.
              </p>
            </div>

            <div className="w-detail right">
              <i className="icon icon-ciurclke" />
              <h4 className="title">CEREMONY</h4>
              <h5 className="title">Saturday 7th March 2020</h5>
              <p>
                We will be having a small private ceremony with only immediate
                family and the wedding party. Details will be sent by email.
              </p>
            </div>

            <div className="w-detail left">
              <i className="icon icon-wine-glass" />
              <h4 className="title">6PM SHARP - RECEPTION</h4>
              <h5 className="title">Saturday 7th March 2020</h5>
              <p>
                <a href="https://www.goatbeer.com.au/">Mountain Goat Beer</a> is
                a brewery in the inner-Melbourne suburb of Richmond. We can't
                wait to celebrate with everyone!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentTimeline;
