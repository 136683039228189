import React from 'react';

const ContentHero = () => {
  return (
    <div className="container-fluid hero">
      <div className="hero-content">
        <h1 className="cursive">
          Jeremy <i className="icon icon-heart" /> Erica
        </h1>
      </div>
    </div>
  );
};

export default ContentHero;
