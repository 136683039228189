import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { getInvitation, updateInvitation, setCode } from '../actions';

import SingleForm from './forms/SingleForm';
import PlusOneForm from './forms/PlusOneForm';
import BothForm from './forms/BothForm';
import { renderInput } from './forms/renderInput';
import { renderSubmitButton } from './forms/renderSubmitButton';

class ContentRSVPNew extends Component {
  componentDidMount() {
    if (!this.props.codeFromUri) {
      return;
    }
    this.onSubmit({ code: this.props.codeFromUri });
  }

  onSubmit = formValues => {
    this.props.setCode(formValues.code);
  };

  render() {
    return (
      <div className="container-fluid rsvp-area">
        <div className="hero-content">
          <div className="row">
            <div className="col-sm-2 col-md-2 col-lg-3" />
            <div className="col-sm-8 col-md-8 col-lg-6">
              <div className="rsvp-box">
                <h2 className="cursive">RSVP</h2>
                {this.renderWizard()}
              </div>
            </div>
          </div>

          {this.renderFooter()}
          <div className="col-sm-2 col-md-2 col-lg-3" />
        </div>
      </div>
    );
  }

  renderWizard() {
    if (
      this.props.invitations &&
      this.props.code &&
      this.props.invitations[this.props.code] &&
      this.props.invitations[this.props.code].completed === true
    ) {
      return this.renderWizardStepThree();
    }

    if (
      this.props.invitations &&
      this.props.code &&
      this.props.invitations.hasOwnProperty(this.props.code)
    ) {
      return this.renderWizardStepTwo();
    }

    // When a user provides an invalid code (invitations contains a key of undefined), re-render step one
    if (
      this.props.invitations &&
      this.props.code &&
      this.props.invitations.hasOwnProperty(undefined)
    ) {
      return this.renderWizardStepOne(
        'Invalid code, if you are experincing difficulty with your code please contact Jeremy or Erica'
      );
    }

    return this.renderWizardStepOne();
  }

  renderWizardStepOne(message = '') {
    return (
      <>
        <div className="form-group mx-sm-3 mb-2">
          <form
            onSubmit={this.props.handleSubmit(this.onSubmit)}
            autoComplete="off"
          >
            <Field
              name="code"
              component={renderInput}
              label={message}
              type="text"
              placeholder="Please enter your RSVP code"
            />
            {renderSubmitButton()}
          </form>
        </div>
      </>
    );
  }

  renderWizardStepTwo() {
    let i = this.props.invitations[this.props.code];

    if (i.allowedAttendees === '1') {
      return (
        <>
          {this.renderMessage()}
          <SingleForm onSubmit={this.onSubmit} code={this.props.code} />
        </>
      );
    }
    if (i.allowedAttendees === '2' && !i.personTwo) {
      return (
        <>
          {this.renderMessage()}
          <PlusOneForm onSubmit={this.onSubmit} code={this.props.code} />
        </>
      );
    }
    if (i.allowedAttendees === '2' && i.personTwo) {
      return (
        <>
          {this.renderMessage()}
          <BothForm onSubmit={this.onSubmit} code={this.props.code} />
        </>
      );
    }
  }

  renderWizardStepThree() {
    return (
      <div className="wizardStep">
        <p>Thanks for RSVPing</p>
        <p>
          If you need to update any details, please speak with Jeremy or Erica.
        </p>
      </div>
    );
  }

  renderMessage() {
    let i = this.props.invitations[this.props.code];

    let greeting;
    let body;
    if (i.personTwo && i.allowedAttendees === '2') {
      greeting = `Dear ${i.personOne} and ${i.personTwo},`;
      body = 'We would love for you both';
    } else if (i.allowedAttendees === '2') {
      greeting = `Dear ${i.personOne},`;
      body = 'We would love for you and a guest';
    } else {
      greeting = `Dear ${i.personOne},`;
      body = 'We would love for you';
    }

    body +=
      ' to celebrate our wedding on Satuday 7th March 2020. Please RSVP by completing the form below.';

    return (
      <>
        <p>{greeting}</p>
        <p>{body}</p>
        <p>Love, Jeremy and Erica</p>
        <hr />
      </>
    );
  }

  renderFooter() {
    return (
      <div className="row">
        <div className="container text-center">
          <p className="copyright">
            <a
              href="https://colorlib.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Design inspired by Colorlib.
            </a>{' '}
            Modified by <a href="https://github.com/tennantje">Jeremy</a> under
            Creative Commons 3.0.
          </p>
        </div>
      </div>
    );
  }
}

const validate = formValues => {
  const errors = {};

  if (formValues.code && formValues.code.length !== 6) {
    errors.code =
      'Please contact Jeremy or Erica if you are having difficulty with your code (6 characters)';
  }
  return errors;
};

const mapStateToProps = (state, ownProps) => {
  return {
    codeFromUri: ownProps.match.params.id,
    code: state.invitations.code,
    invitations: state.invitations.invitations
  };
};

const formWrapped = reduxForm({
  form: 'rsvpForm',
  validate
})(ContentRSVPNew);

export default connect(
  mapStateToProps,
  { getInvitation, updateInvitation, setCode }
)(formWrapped);
