import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import ContentHero from './ContentHero';
import ContentTimeline from './ContentTimeline';
import ContentGoat from './ContentGoat';
import ContentColumns from './ContentColumns';
import ContentRSVP from './ContentRSVP';

class App extends Component {
  render() {
    return (
      <>
        <BrowserRouter>
          <ContentHero />
          <ContentTimeline />
          <ContentGoat />
          <ContentColumns />
          <Route path="/" exact component={ContentRSVP} />
          <Route path="/:id" exact component={ContentRSVP} />
        </BrowserRouter>
      </>
    );
  }
}

export default App;
