import _ from 'lodash';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import jeremyanderica from '../apis/jeremyanderica';

import { INVITATIONS_ONE, INVITATIONS_UPDATE, SET_CODE } from './types';

export const setCode = code => async dispatch => {
  dispatch({
    type: SET_CODE,
    payload: code
  });
  if (code && code.length === 6) {
    dispatch(getInvitation(code));
  }
};

export const getInvitation = code => async dispatch => {
  dispatch(showLoading());
  dispatch({
    type: INVITATIONS_ONE,
    payload: {
      invitationsOne: {
        status: 'Requested',
        message: `Invitation ${code} requested`
      }
    }
  });

  try {
    let requestStatus = await jeremyanderica.get(`/invitations/${code}`);
    requestStatus = _.mapKeys(requestStatus.data, 'code');
    dispatch({
      type: INVITATIONS_ONE,
      payload: {
        invitations: requestStatus,
        invitationsOne: {
          status: 'Success',
          message: `Fetch invitation ${code} successful`
        }
      }
    });
  } catch (error) {
    dispatch({
      type: INVITATIONS_ONE,
      payload: {
        invitationsOne: {
          status: 'Failure',
          message: 'Fetch unsuccessful'
        }
      }
    });
  }
  dispatch(hideLoading());
};

export const updateInvitation = (code, form) => async dispatch => {
  dispatch(showLoading());
  dispatch({
    type: INVITATIONS_UPDATE,
    payload: {
      invitationsUpdate: {
        status: 'Requested',
        message: `Update invitation ${code} requested`
      }
    }
  });

  try {
    let requestStatus = await jeremyanderica.patch(`/invitations/${code}`, {
      ...form
    });
    requestStatus = _.mapKeys(requestStatus.data, 'code');
    dispatch({
      type: INVITATIONS_UPDATE,
      payload: {
        invitations: requestStatus,
        invitationsUpdate: {
          status: 'Success',
          message: 'Fetch successful'
        }
      }
    });
    dispatch(hideLoading());
  } catch (error) {
    dispatch({
      type: INVITATIONS_UPDATE,
      payload: {
        invitationsUpdate: {
          status: 'Failure',
          message: 'Fetch unsuccessful'
        }
      }
    });
    dispatch(hideLoading());
  }
};
