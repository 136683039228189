import React, { Component } from 'react';

class ContentGallery extends Component {
  renderImage(imageFilename, description) {
    return (
      <div className="col-md-4 col-sm-6 ">
        <img
          className="gallery"
          src={`images/${imageFilename}`}
          alt={description}
          width="100%"
        />
      </div>
    );
  }
  renderRows() {
    return (
      <>
        <div className="row">
          {this.renderImage('gallery-1-sm.jpg', 'Whistler')}
          {this.renderImage('gallery-2-sm.jpg', 'Our engagement')}
          {this.renderImage('gallery-3-sm.jpg', "Erica's Birthday")}
        </div>
        <div className="row">
          {this.renderImage('gallery-4-sm.jpg', 'Celebrating a special day')}
          {this.renderImage('gallery-5-sm.jpg', 'At Rosebud with Milton')}
          {this.renderImage('gallery-6-sm.jpg', 'Milk the Cow')}
        </div>
        <div className="row">
          {this.renderImage('gallery-7-sm.jpg', 'The Surly Goat')}
          {this.renderImage('gallery-8-sm.jpg', 'Picnic in the park')}
          {this.renderImage('gallery-9-sm.jpg', 'Relaxing in Vancouver')}
        </div>
      </>
    );
  }
  render() {
    return (
      <>
        <h2 className="cursive supersized extrapadding">The Couple</h2>
        <h3 className="serif supersized extrapadding">&amp; Milton</h3>
        {this.renderRows()}
      </>
    );
  }
}

export default ContentGallery;
