import React from 'react';
import ContentColumnsLogistics from './ContentColumnsLogistics';
import ContentGallery from './ContentGallery';

const ContentColumns = () => {
  return (
    <div className="container wedding-columns">
      <div className="row">
        <div className="col-sm-1" />
        <div className="col-sm-10">
          <ContentColumnsLogistics />
          <ContentGallery />
        </div>
        <div className="col-sm-1" />
      </div>
    </div>
  );
};

export default ContentColumns;
