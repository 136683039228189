import React from 'react';

export const renderInput = ({ input, label, type, placeholder, meta }) => {
  const className = `field ${
    meta.error && meta.touched ? 'form-control is-invalid' : 'form-control'
  }`;
  return (
    <div className="form-group">
      <label>{label}</label>
      <input
        className={className}
        type={type}
        placeholder={placeholder}
        {...input}
      />
      {_renderError(meta)}
    </div>
  );
};

const _renderError = ({ error, touched }) => {
  if (touched && error) {
    return <div className="invalid-feedback">{error}</div>;
  }
};
