import {
  INVITATIONS_ONE,
  INVITATIONS_UPDATE,
  SET_CODE
} from '../actions/types';

export default (state = {}, action) => {
  switch (action.type) {
    case INVITATIONS_ONE:
      return {
        ...state,
        ...action.payload
      };
    case INVITATIONS_UPDATE:
      return {
        ...state,
        ...action.payload
      };
    case SET_CODE:
      return {
        ...state,
        code: action.payload
      };
    default:
      return state;
  }
};
