import React from 'react';

export const renderSubmitButton = isLoading => {
  if (isLoading) {
    return (
      <button type="submit" className="btn disabled" disabled>
        Request Submitted
      </button>
    );
  } else {
    return (
      <button type="submit" className="btn">
        Submit
      </button>
    );
  }
};
